import FullLaout from "../../component/FullLayout"
import Good from "../../component/Good"
import { RightOutline, DownlandOutline } from 'antd-mobile-icons'
import { NavBar, NoticeBar, Image } from 'antd-mobile'
import useHomeList from "../../hooks/component/useHomeList"
import { useEffect } from "react"
import BaseBox from "../../component/BaseBox"
import BaseBoxBg from "../../component/BaseBoxBg"
import MarginBox from "../../component/MarginBox"
import logo from "../../images/img/logo.png"
import useAPI from "../../hooks/common/useAPI"
import useDownload from "../../hooks/common/useDownload"
import './index.less'






const DownloadApp = () => {

    const { request } = useAPI()
    const { initOp } = useDownload()
    const downloadApp = async () => {
        if (!window?.download_url) {
            let rs = await request('/app/download_url')
            console.log('rsrrs', rs)
            window.download_url = rs?.data
        }

        if (window.isIos()) {
            window.location.href = window?.download_url?.ios_url
            //    if(process.env.REACT_APP_LANG == 'hk') window.showIosTip()
            //     const iframe = document.createElement('iframe');
            //     iframe.style.display = 'none';
            //     iframe.src = window?.download_url?.ios_url;    //这种下载方式 ， 不用设置文件类型
            //     document.body.appendChild(iframe);
            //     setTimeout(() => {
            //       document.body.removeChild(iframe);
            //     }, 5000);

        } else {
            window.location.href = window?.download_url?.android_url
        }

    }


    return <>
        <div className="d-box"   {... (process.env.REACT_APP_LANG == 'vi'&& {onClick:() => { downloadApp() }})} {... (process.env.REACT_APP_LANG == 'hk' && {id:"openinstall-download"})}>

                <BaseBoxBg style={{ opacity: "0.8", height:"100vh",  position:"absolute" }}></BaseBoxBg>
                    <div style={{ height: "100vh" }}>
                    {/* scene： 3动漫推广页，其他值普通推广页 */}
                    <img src={`${ window.params?.scene == 3 ? require('../../images/img/33.png'):require('../../images/img/2.jpg')}`} style={{ width: "100%" }} />

                        {/* <div style={{height:"100%",display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                            <div style={{ padding: "0.3rem", display: "flex", alignItems: "center" }}>
                                <img src={require('../../images/img/logo.png')} style={{ width: "0.4rem" }} />
                                <div style={{ fontSize: "0.22rem", fontWeight: "bold", color: "#FCFCFD", marginLeft: "0.1rem" }}>Plus18 Games</div>


                            </div>
                            <div style={{display:"flex",  justifyContent:"center", marginBottom:"0.6rem"}}><img src={`${ window.isIos() ? require('../../images/img/ios.png'):require('../../images/img/and.png')}`} style={{ width: "1.6rem" }} /></div>
                        </div> */}
                    </div>
                
        </div>
        {/* <div style={{height:"60vh"}}  {... (process.env.REACT_APP_LANG == 'vi'&& {onClick:() => { downloadApp() }})} {... (process.env.REACT_APP_LANG == 'hk' && {id:"openinstall-download"})}>
            <div style={{color:"#fefefe", fontSize:"0.22rem" , fontWeight:"bold", margin:"0.2rem 0", textAlign:"center"}}>下載APP，獲得更多權益</div>
            <img src={require('../../images/gift.jpg')} style={{width:"100%", borderRadius:"10px"} }/>
        </div> */}
    </>
}
export default DownloadApp